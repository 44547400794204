import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';

import Scroller from '../../components/Scroller/Scroller';

import styles from '../../styles/pages/example-pages.module.scss';

const CheckoutCanceled: FC<RouteComponentProps> = () => (
  <Scroller>
    <article className={styles.content}>
      <h1>申し訳ございません</h1>
      <p>
        申し訳ございませんが、ご購入を完了することができませんでした。別の方法をお試しください。別の支払方法で購入を完了させてください。
      </p>
      <p>
        詳しくは、<a href="https://smartpay.co/faq">FAQ</a>をご覧ください。
      </p>
      <a href="/">店に戻します → </a>
    </article>
  </Scroller>
);

export default CheckoutCanceled;
